<template>
   <div class="form">
    <el-form class="content bascform" ref="form" :model="form" label-width="100px">
        <div class="choice">
          <el-radio-group v-model="form.radio" @change="choiceChange">
              <el-radio label="1">实物返回</el-radio>
              <el-radio label="2">返修折款</el-radio>
              <el-radio label="3">实物返回+折款</el-radio>
          </el-radio-group>
        </div>
        <!-- 实物返回 -->
        <div v-if="mediumShow">
          <div class="col col4">
              <el-form-item label="返回部件"></el-form-item>
          </div>
          <div class="content bascform">
            <div class="tableList" >
              <div class="table">
                <el-table  style="width: 100%" ref="returnData" :data="returnData">
                  <el-table-column prop="materialCode" label="物料编号" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="materialDesc"  label="物料描述" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="materialNum"  label="待处理数量" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="returnNum"  label="返回数量" :show-overflow-tooltip="true">
                    <template #default="scope">
                      <el-input v-model="scope.row.returnNum" @input="(val)=>{scope.row.returnNum}" placeholder="请输入"></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="col col4">
            <el-form-item label="收货地址">
              <span class="onlyText">
              {{form.receivingAddress}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4" >
            <el-form-item label="送达日期" >
              <el-date-picker type="date" placeholder="预计送达日期" v-model="form.deliveryDate" @change="timeForm"></el-date-picker>
            </el-form-item>
          </div>
        </div>
        <!-- 返修折款 -->
        <div v-if="tableShow">
            <div class="content bascform">
            <div class="tableList" >
              <div class="table">
                <el-table  style="width: 100%" ref="RepairSheKuan" :data="RepairSheKuan">
                  <el-table-column prop="materialCode" label="物料编号" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="materialDesc"  label="物料描述" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="materialNum"  label="待处理数量" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="returnNum"  label="折款数量" :show-overflow-tooltip="true">
                    <template #default="scope">
                      <el-input v-model="scope.row.returnNum" @input="(val)=>{scope.row.returnNum}" placeholder="请输入"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="discountingMoney"  label="折款金额">
                    <template #default="scope">
                      <el-input v-model="scope.row.discountingMoney" @input="(val)=>{scope.row.discountingMoney}" placeholder="请输入"></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
            <div class="total">合计折款:￥{{sum}}</div>
          </div>
          <!-- 实物折回+折款 -->
        <div v-if="kindReturn">
          <div class="forrepair">
            <el-form-item label="返回部件" prop="">
              <el-button  type="primary" size="mini" @click="choiceSn">+选择部件</el-button>
            </el-form-item>
            <div class="content bascform">
              <div class="tableList" >
                <div class="table">
                  <el-table  style="width: 100%" ref="returnRepairSheKuan" :data="returnRepairSheKuan">
                    <el-table-column prop="materialCode" label="物料编号"></el-table-column>
                    <el-table-column prop="materialDesc"  label="物料描述" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="materialNum"  label="待处理数量"></el-table-column>
                    <el-table-column prop="returnNum"  label="返回数量">
                      <template #default="scope">
                        <el-input v-model="scope.row.returnNum" @input="(val)=>{scope.row.returnNum}" placeholder="请输入"></el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        <div class="col col4">
          <el-form-item label="收货地址">
            <span class="onlyText">
            {{form.receivingAddress}}
            </span>
          </el-form-item>
        </div>
        <div class="col col4" >
          <el-form-item label="送达日期">
            <el-date-picker type="date" placeholder="预计送达日期" v-model="form.deliveryDate" @change="timeForm"></el-date-picker>
          </el-form-item>
        </div>
        <div class="forrepair">
          <el-form-item label="折款部件" prop="">
            <el-button  type="primary" size="mini" @click="choiceSn">+选择部件</el-button>
          </el-form-item>
          <div class="content bascform">
            <div class="tableList" >
              <div class="table">
                <el-table  style="width: 100%" ref="zhekuanbj" :data="zhekuanbj">
                  <el-table-column prop="materialCode" label="物料编号"></el-table-column>
                  <el-table-column prop="materialDesc"  label="物料描述" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="materialNum"  label="待处理数量"></el-table-column>
                  <el-table-column prop="returnNum"  label="折款数量">
                    <template #default="scope">
                      <el-input v-model="scope.row.returnNum" @input="(val)=>{scope.row.returnNum}" placeholder="请输入"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="discountingMoney"  label="折款金额">
                    <template #default="scope">
                      <el-input v-model="scope.row.discountingMoney" @input="(val)=>{scope.row.discountingMoney}" placeholder="请输入"></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="total">合计折款:￥{{sum}}</div>
        </div>
        </div>
    </el-form>
  </div>
  <dialog-box  dialogWidth="826px" :dialogShow="dialogShow" :buttonData="buttonData" title="选择部件"  @handleClose="handleClose"  componentName="ChoiceDialog"></dialog-box>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { tableField, tableData } from './js/shipmentsBtnDialog'
export default {
  name: 'ShipmentsBtnDialog',
  components: Component.components,
  props: ['receivingData'],
  data: function () {
    return {
      tableField: tableField,
      tableData: tableData,
      returnData: [],
      RepairSheKuan: [],
      returnRepairSheKuan: [],
      zhekuanbj: [], // 折款部件
      dialogShow: false,
      mediumShow: true,
      tableShow: false,
      kindReturn: false,
      buttonData: [
        {
          label: '取消',
          action: 'handleClose',
          size: 'mini'
        },
        {
          label: '确认',
          size: 'mini',
          action: 'handleAffirm',
          type: 'primary'
        }
      ],
      form: {
        radio: '1'
      },
      sum: ''
    }
  },
  mounted () {
    this.Realreturns()
    this.form.receivingAddress = this.receivingData.receivingAddress
  },
  methods: {
    Realreturns () { // 发货折款
      var id = this.$route.query.id
      requestForm('/api/repair/repair/queryShippingComponentMaterial?id=' + id, 'get').then((res) => {
        console.log('res', res)
        if (res.code === '200') {
          this.returnData = res.data.repairDetailsEntities
          this.RepairSheKuan = res.data.repairDetailsEntities
          this.returnRepairSheKuan = res.data.repairDetailsEntities
          this.zhekuanbj = res.data.repairDetailsEntities
          this.sum = res.data.sum
        }
      })
    },
    choiceChange (val) {
      if (val === '1') {
        this.mediumShow = true
        this.tableShow = false
        this.kindReturn = false
      } else if (val === '2') {
        this.mediumShow = false
        this.tableShow = true
        this.kindReturn = false
      } else {
        this.kindReturn = true
        this.mediumShow = false
        this.tableShow = false
      }
    },
    choiceSn () {
      this.dialogShow = true
    },
    handleClose () {
      this.dialogShow = false
    },
    timeForm () {
      if (this.form.deliveryDate) {
        this.form.deliveryDate = dayjs(this.form.deliveryDate).format('YYYY-MM-DD')
      } else {
        this.form.deliveryDate = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/tableList.scss';
.form{
  .choice{
    padding: 20px 50px;
  }
    :deep(.el-input__inner){
        width:60%
    }
    :deep(.tableList){
      padding: 0px 0px 20px 30px;
    }
    .total{
        float: right;
        padding: 10px;
    }
  }

</style>
